// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---packages-blog-gatsby-blog-core-src-templates-collection-category-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.category.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-category-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-collection-tag-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.tag.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-tag-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-post-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/post.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-post-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-posts-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/posts.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-posts-js" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-authors-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/authors.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-authors-jsx" */),
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-about-community-jsx": () => import("./../../../src/pages/about/community.jsx" /* webpackChunkName: "component---src-pages-about-community-jsx" */),
  "component---src-pages-about-company-history-jsx": () => import("./../../../src/pages/about/company-history.jsx" /* webpackChunkName: "component---src-pages-about-company-history-jsx" */),
  "component---src-pages-about-environment-jsx": () => import("./../../../src/pages/about/environment.jsx" /* webpackChunkName: "component---src-pages-about-environment-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-about-management-jsx": () => import("./../../../src/pages/about/management.jsx" /* webpackChunkName: "component---src-pages-about-management-jsx" */),
  "component---src-pages-about-regional-history-jsx": () => import("./../../../src/pages/about/regional-history.jsx" /* webpackChunkName: "component---src-pages-about-regional-history-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-esg-index-jsx": () => import("./../../../src/pages/esg/index.jsx" /* webpackChunkName: "component---src-pages-esg-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-investors-index-jsx": () => import("./../../../src/pages/investors/index.jsx" /* webpackChunkName: "component---src-pages-investors-index-jsx" */),
  "component---src-pages-media-featured-articles-jsx": () => import("./../../../src/pages/media/featured-articles.jsx" /* webpackChunkName: "component---src-pages-media-featured-articles-jsx" */),
  "component---src-pages-media-index-jsx": () => import("./../../../src/pages/media/index.jsx" /* webpackChunkName: "component---src-pages-media-index-jsx" */),
  "component---src-pages-media-photo-gallery-jsx": () => import("./../../../src/pages/media/photo-gallery.jsx" /* webpackChunkName: "component---src-pages-media-photo-gallery-jsx" */),
  "component---src-pages-media-video-interviews-jsx": () => import("./../../../src/pages/media/video-interviews.jsx" /* webpackChunkName: "component---src-pages-media-video-interviews-jsx" */),
  "component---src-pages-projects-beaver-mine-jsx": () => import("./../../../src/pages/projects/beaver-mine.jsx" /* webpackChunkName: "component---src-pages-projects-beaver-mine-jsx" */),
  "component---src-pages-projects-castle-east-jsx": () => import("./../../../src/pages/projects/castle-east.jsx" /* webpackChunkName: "component---src-pages-projects-castle-east-jsx" */),
  "component---src-pages-projects-castle-mine-jsx": () => import("./../../../src/pages/projects/castle-mine.jsx" /* webpackChunkName: "component---src-pages-projects-castle-mine-jsx" */),
  "component---src-pages-projects-index-jsx": () => import("./../../../src/pages/projects/index.jsx" /* webpackChunkName: "component---src-pages-projects-index-jsx" */),
  "component---src-pages-projects-lowney-lac-edouard-jsx": () => import("./../../../src/pages/projects/lowney-lac-edouard.jsx" /* webpackChunkName: "component---src-pages-projects-lowney-lac-edouard-jsx" */),
  "component---src-pages-projects-nourricier-jsx": () => import("./../../../src/pages/projects/nourricier.jsx" /* webpackChunkName: "component---src-pages-projects-nourricier-jsx" */),
  "component---src-pages-projects-saint-denis-jsx": () => import("./../../../src/pages/projects/saint-denis.jsx" /* webpackChunkName: "component---src-pages-projects-saint-denis-jsx" */),
  "component---src-pages-projects-shillington-jsx": () => import("./../../../src/pages/projects/shillington.jsx" /* webpackChunkName: "component---src-pages-projects-shillington-jsx" */),
  "component---src-pages-projects-violet-mine-jsx": () => import("./../../../src/pages/projects/violet-mine.jsx" /* webpackChunkName: "component---src-pages-projects-violet-mine-jsx" */)
}

